<template>
  <div>
    <b-row class="mb-2">
      <b-col md="12" style="display: flex; gap:10px;">
        <export-button
          :filter="exportFilter"
          url="/sw3/export-list"
          :disabled="loadingData || !dataItems.length"
          file-title="Live Sport report.xlsx"
        />
        <b-button v-b-modal.modal-hierachy v-if="width<768">
          <feather-icon icon="GitPullRequestIcon" />
        </b-button>
      </b-col>
    </b-row>
    <b-card>
      <!-- FILTER START -->
      <b-row>
        <b-col
          md="3"
          class="d-flex align-items-center justify-content-center mb-1"
        >
          <b-form-group :label="$t('labels.ticket_id')">
            <b-form-input
              v-model="filterSelect.ticketId"
              class="d-inline-block mr-1"
              :placeholder="$t('labels.ticket_id')"
              @input="inputSelect"
            />
          </b-form-group>
          <div style="padding-left:10px;">
            <b-button
              variant="primary"
              type="button"
              class="btn btn-icon rounded-circle mr-1 btn-primary btn-sm"
              v-b-tooltip.hover.top="$t('labels.search')"
              @click="modalSearchTicketId()"
              size="sm"
            >
              <feather-icon icon="SearchIcon" />
            </b-button>
          </div>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('labels.type')">
            <v-select
              :value="$store.value"
              v-model="filterSelect.type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="typeOptions"
              label="key"
              @input="inputSelect"
              :reduce="(type) => type.value"
              :placeholder="$t('select_type')"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('labels.datefrom')" rules="required">
            <flat-pickr
              v-model="filterSelect.dateFrom"
              @input="inputSelect"
              class="form-control"
              :config="{
                          altInput: true,
                          altFormat: 'd-m-Y h:i K',
                          enableTime: true,
                          wrap : true,
                          plugins: [
                             new yearDropdownPlugin({
                               yearStart: 100,
                               yearEnd: 0
                            })
                           ],
                        }"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('labels.dateto')" rules="required">
            <flat-pickr
              v-model="filterSelect.dateTo"
              @input="inputSelect"
              class="form-control"
              :config="{
                          altInput: true,
                          altFormat: 'd-m-Y h:i K',
                          enableTime: true,
                          wrap : true,
                          plugins: [
                             new yearDropdownPlugin({
                               yearStart: 100,
                               yearEnd: 0
                            })
                           ],
                        }"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- FILTER END -->
    </b-card>
    <b-row>
      <b-col md="10">
        <b-card>
          <b-overlay :show="loadingData" variant="transparent" no-wrap />
          <b-modal
            id="modalResponse"
            title="PopUp Response"
            cancel-variant="outline-secondary"
            scrollable
          >
            <b-card-text>
              {{ rows }}
            </b-card-text>
          </b-modal>
          <!-- search -->
          <b-col
            md="3"
            class="d-flex align-items-center justify-content-center mb-1"
          >
            <b-form-input
              v-model="search"
              class="d-inline-block mr-1"
              :placeholder="this.$t('filter')"
            />
          </b-col>
          <!-- table -->
          <b-table
            md="9"
            :items="dataItems"
            :fields="fields"
            class="scroll"
            responsive
          >
            <template #cell(referenceId)="data">
              <b-button
                variant="outline-primary"
                :to="{ name: 'apps-ticket-sports-live-detail', params: { id: data.item.referenceId } }"
                >{{
                    data.item.referenceId
                }}
              </b-button>
            </template>
            <template #cell(ticketStatus)="data">
              <span
                v-if="data.item.ticketStatus == 'Pendiente'"
                style="width: %100; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #03a9f4; color:#FFFFFF;"
              >
                {{ $t('status.pending') }}</span
              >
              <span
                v-else-if="data.item.ticketStatus == 'perdedora'"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #f44336; color:#FFFFFF;"
              >
                {{ $t('status.lost') }}</span
              >
              <span
                v-else-if="data.item.ticketStatus == 'ganadora'"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #8bc34a; color:#FFFFFF;"
              >
                {{ $t('status.won') }}</span
              >
              <span
                v-else-if="data.item.ticketStatus == 'anulada'"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #25476a; color:#FFFFFF;"
              >
                {{ $t('status.null_bet') }}</span
              >
              <span
                v-else-if="data.item.ticketStatus == 'pagada'"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #8bc34a; color:#FFFFFF;"
              >
                {{ $t('status.paid') }}</span
              >
              <span
                v-else-if="data.item.ticketStatus == 'expirada'"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #25476a; color:#FFFFFF;"
              >
                {{ $t('status.expired') }}</span
              >
              <span
                v-else-if="data.item.ticketStatus == 'cashout'"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #25476a; color:#FFFFFF;"
              >
                {{ $t('status.return') }}</span
              >
              <span
                v-else-if="data.item.ticketStatus == 'enjuego'"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #ffb300; color:#FFFFFF;"
              >
                {{ $t('status.ingame') }}</span
              >
            </template>
            <template #cell(date)="data">
              {{ data.item.date | moment }}
            </template>
            <template #cell(amount)="data">
              {{ data.item.amount | currency({ symbol: "" }) }}
            </template>
          </b-table>
          <b>{{$t('totals')}}</b>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >{{ $t('labels.showing') }} {{ "-" }} {{ $t('labels.to') }}
                  {{
                    "-"
                  }}
                  {{ $t('labels.of') }} {{ "-" }}
                  {{ $t('labels.entries') }}</span
                >
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="serverParams.page"
                  :total-rows="totalRows"
                  :per-page="serverParams.limit"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                  :simple="true"
                  :hide-goto-end-buttons="true"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-modal
        id="modal-hierachy"
        hide-footer
        :title="$t('agent_list')"
        v-if="width<768"
      >
        <HierarchyUsers
          @selectUser="showData"
          ref="HerarchiComponent"
        />
      </b-modal>
      <b-col class="p-0" md="2" v-else>
        <b-card no-body class="p-0 py-1">
          <HierarchyUsers @selectUser="showData" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import axios from '@axios';
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import HierarchyUsers from "@core/components/HierarchyUsers/HierarchyUsers.vue";
import yearDropdownPlugin from "@/helpers/year_flatpickr_plugin";
import ExportButton from "@/views/pages/export/ExportButton.vue";

export default {
  components: {
    ExportButton,
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    flatPickr,
    ToastificationContent,
    HierarchyUsers
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      loadingData: false,
      dataItems: [],
      dataIdUser: [],
      pages: [20, 40, 60],
      search: "",
      rows: [],
      serverParams: {
        page: 1,
        limit: 20,
      },
      totalRows: 0,
      filterSelect: {
        ticketId: "",
        selectType: "",
        type: "",
        dateFrom: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        dateTo: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      },
      dataReportHistory: [],
      exportFilter: {},
      width: 0,
    };
  },
  computed: {
    fields(){
      return [
        { label: this.$t('origin'), key: "origen" },
        { label: this.$t('labels.ticket_id'), key: "referenceId" },
        { label: this.$t('labels.userName'), key: "user" },
        { label: this.$t('labels.date'), key: "date" },
        { label: this.$t('labels.status'), key: "ticketStatus" },
        { label: this.$t('betted_amount'), key: "amount" },
      ]
    },
    typeOptions(){
      return  [
        { key: this.$t('labels.all'), value: "" },
        { key: this.$t('status.ingame'), value: "enjuego" },
        { key: this.$t('status.won'), value: "ganadora" },
        { key: this.$t('status.lose'), value: "perdedora" },
        { key: this.$t('status.null_bet'), value: "anulada" },
        { key: this.$t('status.return'), value: "cashout" },
      ]
    },
    productsOptions() {
      return this.$store.getters['user/productsUserOptions']
    },
    cardCabezera() {
      return [
        {
          statistic: 1.866,
          color: "success",
          icon: "PlayCircleIcon",
          title: "Total Spins",
        },
        {
          statistic: "48.323,50 AR$",
          color: "danger",
          icon: "ActivityIcon",
          title: "Total Sales",
        },
        {
          statistic: "41.935,50 AR$",
          color: "success",
          icon: "TrophyIcon",
          title: "Total Wins",
        },
        {
          statistic: "6.388,00 AR$",
          color: "danger",
          icon: "DollarSignIcon",
          title: "Total Profit",
        },
      ];
    },
  },
  // add filters
  filters: {
    moment: function (date) {
      return moment(date).format("MM/DD/YYYY HH:mm:ss");
    },
  },

  methods: {
    yearDropdownPlugin,
    /*List - Index*/
    async showData({ id }) {
      this.dataIdUser = id
      try {
        this.loadingData = true;
        const VUE_APP_URL = process.env.VUE_APP_URL
        const params = {
          "id": id,
          "whitelabel": this.$store.state.whitelabelCurrencyNabvar.whitelabel._id,
          "clientId": this.$store.state.whitelabelCurrencyNabvar.whitelabel.client,
          "currency": this.$store.state.whitelabelCurrencyNabvar.currency,
          "ticketId": this.filterSelect.ticketId,
          "type": this.filterSelect.type,
          "dateFrom": this.filterSelect.dateFrom,
          "dateAt": this.filterSelect.dateTo,
        }
        this.exportFilter = params

        const result = await axios.post(`${VUE_APP_URL}/sw3/list`, params)
        this.dataItems = result.data.dataGateway;

        return result

      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching data list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingData = false;
      }
    },

    modalSearchTicketId() {
      try {
        this.loadingData = true;
        const VUE_APP_URL = process.env.VUE_APP_URL
        const params = {
          whitelabel: this.$store.state.whitelabelCurrencyNabvar.whitelabel._id,
        }
        const result = axios
          .post(`${VUE_APP_URL}/sw3/` + this.filterSelect.ticketId + `/details`, params)
          .then(r => {
            const searchId = r.data[0].referenceId
            this.$router.push({ name: 'apps-ticket-sports-live-detail', params: { id: searchId } });
          })
          .catch(error => {
            console.log('Error Encontrado:', error.response)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching ticket",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          })

        return result

      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching ticket",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    onPageChange(page) {
      console.log(page, "page");
      this.serverParams.page = page;
      this.showData();
    },
    onPerPageChange(limit) {
      this.serverParams.page = 1;
      this.serverParams.limit = limit;
      this.showData();
    },
    inputSelect() {
      let id = this.dataIdUser
      this.showData({ id });
    },

  },
  created() {
    this.width = window.innerWidth;
    window.addEventListener("resize", () => {
      this.width = window.innerWidth;
    });
  },
  mounted() {
  },
  destroyed() {
    if (store.hasModule("reports")) store.unregisterModule("reports");
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

.scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}

.scroll::-webkit-scrollbar-button:increment,
.scroll::-webkit-scrollbar-button {
  display: none;
}

.scroll::-webkit-scrollbar:horizontal {
  height: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
}

.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
